import React from 'react'
import { graphql } from 'gatsby'

import ContactSuccess from '../components/contactSuccess/ContactSuccess'
import Layout from '../components/layout/Layout'
import SEO from '../components/seo'

const ContactSuccessPage = (props) => (
  <Layout navbarData={props.data.contentfulHomePage.navbar}>
    <SEO title="Thanks for Reaching Out" description="Contact form submission success page."/>
    <ContactSuccess name={props.location.state ? props.location.state.name : ""} />
  </Layout>
)

export const query = graphql`
  query ContactSuccessPageQuery {
    contentfulHomePage {
      navbar {
        ...NavbarFragment
      }
    }
  }
`

export default ContactSuccessPage
