import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faTimes, faPhone, faGlobe, faEnvelope, faMapMarkerAlt, faHourglassStart } from '@fortawesome/free-solid-svg-icons'
// import { faClock as farFaClock } from '@fortawesome/free-solid-svg-icons'
import { faClock as farFaClock, faFilePdf as farFaFilePdf } from '@fortawesome/free-regular-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'

// library.add(far)
library.add(faBars)
library.add(faTimes)
library.add(faPhone)
library.add(faGlobe)
library.add(faEnvelope)
library.add(farFaClock)
library.add(faMapMarkerAlt)
library.add(faHourglassStart)
library.add(farFaFilePdf)

export default library