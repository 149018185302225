import React from 'react'

import { colorToStr, ColorType } from '../../utils/color'

import './FooterStyles.scss'

const Footer = ({
  bgColor,
  textColor
}) => {
  return (
    <section className="footer" style={{ backgroundColor: `${colorToStr(bgColor.color[0])}`, color: `${colorToStr(textColor.color[0])}`}}>
      Copyright 2018 © Mireya Soto. All rights reserved.
    </section>
  )
}

Footer.propTypes = {
  textColor: ColorType.isRequired,
  bgColor: ColorType.isRequired,
}

export default Footer;