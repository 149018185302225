import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './NavbarStyles.scss'

const NavButton = ({ disabled, onClick, className, children }) => {
  return (
    <div className="nav-button-container icon">
      <button 
        disabled={disabled}
        className={className}
        onClick={onClick}
      >
        { children }
      </button>
    </div>
  )
}

class Navbar extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      dropdownIsOpen: false
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.dropdownIsOpen && !props.collapsed) {
      return {dropdownIsOpen: false};
    }
    return null;
  }

  _handleDropDownMenuButtonClick = () => {
    this.setState({
      dropdownIsOpen: !this.state.dropdownIsOpen,
    });
  };

  render() {
    const { 
      siteLogo,
      backgroundColor,
      textColor,
      activeTabBackgroundColor,
      activeTabTextColor,
      dropdownBackgroundColor,
      dropdownTextColor,
      dropdownActiveTabBackgroundColor,
      dropdownActiveTabTextColor,
      collapsed,
      fixed,
      fixedPlaceholder
    } = this.props
    const { dropdownIsOpen } = this.state

    let activeStyles = {
      color: `${activeTabTextColor}`,
      backgroundColor: `${activeTabBackgroundColor}`,
      cursor: "default",
    }
    let hoverStyles = {
      '--hover-color': `${activeTabTextColor}`,
      '--hover-bg-color': `${activeTabBackgroundColor}`
    }
    let containerClasses = "nav-wrapper"
    let takeSpaceClasses = "take-space"
    if (collapsed) {
      containerClasses += " collapsed"
      takeSpaceClasses += " collapsed"
      activeStyles = {
        color: `${dropdownActiveTabTextColor}`,
        backgroundColor: `${dropdownActiveTabBackgroundColor}`,
        cursor: "default",
      }
      hoverStyles = {
        '--hover-color': `${dropdownActiveTabTextColor}`,
        '--hover-bg-color': `${dropdownActiveTabBackgroundColor}`
      }
    }
    if (fixedPlaceholder) {
      containerClasses += " fixed-placeholder"
    }
    

    let dropdownClasses = "drop-down-nav-links";
    if (dropdownIsOpen) {
      dropdownClasses += " drop-down-open";
    } else {
      dropdownClasses += " drop-down-closed";
    }

    if (fixed) {
      containerClasses += " fixed";
    }

    let navLinks

    if (fixedPlaceholder) {
      navLinks = (
        <nav>
          <ul>
            <li>
              <div className="placeholder" style={hoverStyles} >
                <span>About</span>
              </div>
            </li>
            <li>
              <div className="placeholder" style={hoverStyles}>
                <span>Services</span>
              </div>
            </li>
            <li>
              <div className="placeholder" style={hoverStyles}>
                <span>Events</span>
              </div>
            </li>
            <li>
              <div className="placeholder" style={hoverStyles}>
                <span>Contact</span>
              </div>
            </li>

          </ul>
        </nav>
      )
    } else {
      navLinks = (
        <nav>
          <ul>
            <li>
              <Link to={'/about'} activeStyle={activeStyles} style={hoverStyles} >
                <span>About</span>
              </Link>
            </li>
            <li>
              <Link to={'/services'} activeStyle={activeStyles} style={hoverStyles}>
                <span>Services</span>
              </Link>
            </li>
            <li>
              <Link to={'/events'} activeStyle={activeStyles} style={hoverStyles}>
                <span>Events</span>
              </Link>
            </li>
            <li>
              <Link to={'/contact'} activeStyle={activeStyles} style={hoverStyles}>
                <span>Contact</span>
              </Link>
            </li>

          </ul>
        </nav>
      )
    }
    return (
      <div className={containerClasses}>
        
        <div className="horiz-nav-links" style={{ backgroundColor: `${backgroundColor}`, color: `${textColor}` }}>

            <NavButton 
              className="icon-button menu"
              onClick={this._handleDropDownMenuButtonClick}
              disabled={!collapsed}

            >
              <FontAwesomeIcon icon="bars" />
            </NavButton>

          <div className={takeSpaceClasses} />

          { siteLogo && 
            <Link to="/" className="navbar-logo-link-wrapper">
              <img src={siteLogo.file.url} alt={siteLogo.description} className="navbar-logo"/>
            </Link>
          }

          <div className={takeSpaceClasses} />

          { !collapsed && !dropdownIsOpen && navLinks }

          <div className="nav-button-container icon" />
         
        </div>
        <div className={dropdownClasses} style={{ backgroundColor: `${dropdownBackgroundColor}`, color: `${dropdownTextColor}` }}>
          { collapsed && navLinks }
        </div>
      </div>
    )
  }
}

Navbar.defaultProps = {
  siteLogo: null,
  backgroundColor: 'FFFFFF',
  textColor: '333333',
  activeTabTextColor: 'FFFFFF',
  activeTabBackgroundColor: '333333',
  dropdownBackgroundColor: 'FFFFFF',
  dropdownTextColor: '333333',
  dropdownActiveTabTextColor: 'FFFFFF',
  dropdownActiveTabBackgroundColor: '333333',
  collapsed: false,
  fixed: true,
  fixedPlaceholder: false,
}

Navbar.propTypes = {
  siteLogo: PropTypes.shape({
    description: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string
    })
  }),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  activeTabTextColor: PropTypes.string,
  activeTabBackgroundColor: PropTypes.string,
  dropdownBackgroundColor: PropTypes.string,
  dropdownTextColor: PropTypes.string,
  dropdownActiveTabTextColor: PropTypes.string,
  dropdownActiveTabBackgroundColor: PropTypes.string,
  collapsed: PropTypes.bool,
  fixed: PropTypes.bool,
  fixedPlaceholder: PropTypes.bool,
};

export default Navbar

export const query = graphql`
  fragment NavbarFragment on ContentfulNavbar {
    siteLogo {
      description
      file {
        url
      }
    }
    backgroundColor {
      ...ColorFragment
    }
    textColor {
      ...ColorFragment
    }
    activeTabBackgroundColor {
      ...ColorFragment
    }
    activeTabTextColor {
      ...ColorFragment
    }
    dropdownBackgroundColor {
      ...ColorFragment
    }
    dropdownTextColor {
      ...ColorFragment
    }
    dropdownActiveTabBackgroundColor {
      ...ColorFragment
    }
    dropdownActiveTabTextColor {
      ...ColorFragment
    }
  }
`