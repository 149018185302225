import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

export const colorToStr = (color) => {
  let result = ""
  switch (color.__typename) {
    case "ContentfulHex":
      result = `#${color.value}`
      break;
    case "ContentfulRgba":
      result = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
      break;
    case "ContentfulHsla":
      result = `hsla(${color.h}, ${color.s}%, ${color.l}%, ${color.a})`
      break;
    default:
      result = null
  }
  return result
}

// export const colorToHexStr = (color) => {
//   let result = ""
//   switch (color.__typename) {
//     case "ContentfulHex":
//       result = `#${color.value}`
//       break;
//     case "ContentfulRgba":
//       result = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
//       break;
//     case "ContentfulHsla":
//       result = `hsla(${color.h}, ${color.s}%, ${color.l}%, ${color.a})`
//       break;
//     default:
//       result = null
//   }
//   return result
// }

export const ColorType = PropTypes.shape({
  color: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        __typename: PropTypes.oneOf(["ContentfulHex"]),
        name: PropTypes.string,
        value: PropTypes.string
      }),
      PropTypes.shape({
        __typename: PropTypes.oneOf(["ContentfulRgba"]),
        name: PropTypes.string,
        r: PropTypes.number,
        g: PropTypes.number,
        b: PropTypes.number,
        a: PropTypes.number
      }),
      PropTypes.shape({
        __typename: PropTypes.oneOf(["ContentfulHsla"]),
        name: PropTypes.string,
        h: PropTypes.number,
        s: PropTypes.number,
        l: PropTypes.number,
        a: PropTypes.number
      })
    ])
  )
})

export const query = graphql`
  fragment ColorFragment on ContentfulColor {
    color {
      ... on ContentfulRgba {
        __typename
        name
        r
        g
        b
        a
      }
      ... on ContentfulHsla {
        __typename
        name
        h
        s
        l
        a
      }
      ... on ContentfulHex {
        __typename
        name
        value
      }
    }
  }
`