import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from 'gatsby'

import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'

import './LayoutStyles.scss'

import '../../utils/iconLibrary'

import { colorToStr, ColorType } from '../../utils/color'

class Layout extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      windowWidth: 0,
    }
  }

  componentDidMount() {
    this._updateWindowDimensions()
    window.addEventListener('resize', this._updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._updateWindowDimensions)
  }

  _updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  render() {
    const { navbarData, landingPage, pageBackgroundColor, pageTextColor, children } = this.props
    let layoutContentStyles = "layout-content"
    if (landingPage) {
      layoutContentStyles += " landing"
    }

    let navbarDataCleaned = { ...navbarData }
    for (let prop in navbarDataCleaned) {
      if (prop !== 'siteLogo') {
        navbarDataCleaned[prop] = colorToStr(navbarDataCleaned[prop].color[0])
      }
    }
    return (
      <div className="wrapper" style={{ backgroundColor: `${colorToStr(pageBackgroundColor.color[0])}`, color: `${colorToStr(pageTextColor.color[0])}` }}>
        <Helmet defer={false}>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" />
          <link href="https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css?family=Belleza" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400" rel="stylesheet" />
        </Helmet>
        
        { landingPage &&
          <div className="landing-wrapper" style={{ backgroundColor: `${navbarDataCleaned.backgroundColor}` }}>
            { navbarDataCleaned && 
              (
                <React.Fragment>
                  <Navbar collapsed={this.state.windowWidth < 980} fixed={false} fixedPlaceholder {...navbarDataCleaned} />
                  <Navbar collapsed={this.state.windowWidth < 980} {...navbarDataCleaned} />
                </React.Fragment>
              )
            }
            { children[0] }
          </div>
        }
        { !landingPage && navbarDataCleaned && 
          (
            <React.Fragment>
              <Navbar collapsed={this.state.windowWidth < 980} fixed={false} fixedPlaceholder {...navbarDataCleaned} />
              <Navbar collapsed={this.state.windowWidth < 980} {...navbarDataCleaned} />
            </React.Fragment>
          )
        }
        <div className={layoutContentStyles}>
          { landingPage ? children.filter((child, index) => index !== 0) : children }
        </div>
        <Footer bgColor={navbarData.backgroundColor} textColor={navbarData.textColor} />
      </div>
    )
  }
}

Layout.defaultProps = {
  landingPage: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  navbarData: PropTypes.shape({
    siteLogo: PropTypes.shape({
      description: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string
      })
    }), 
    backgroundColor: ColorType,
    textColor: ColorType,
    activeTabBackgroundColor: ColorType,
    activeTabTextColor: ColorType,
    dropdownBackgroundColor: ColorType,
    dropdownTextColor: ColorType,
    dropdownActiveTabBackgroundColor: ColorType,
    dropdownActiveTabTextColor: ColorType,
  }).isRequired,
  landingPage: PropTypes.bool,
  pageBackgroundColor: ColorType.isRequired,
  pageTextColor: ColorType.isRequired,
}

export const query = Navbar.query

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteStylesQuery {
        contentfulSiteStyles {
          pageBackgroundColor {
            ...ColorFragment
          }
          pageTextColor {
            ...ColorFragment
          }
        }
      }
    `}
    render={data => (
      <Layout {...props} {...data.contentfulSiteStyles} />
    )}
  />
)